@import url('https://fonts.googleapis.com/css2?family=Tiny5&display=swap');

body {
    background: linear-gradient(to right, #8A2BE2, #4B0082);
    //background-image: url("burn-elmo.gif");
    background-size:100% 100%;
}

.webHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    background: linear-gradient(to right, #8A2BE2, #4B0082);
}

.fila {
    width: 100%;
    font-family: tiny5, arial;
    font-weight: 400;
    font-style: normal;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.display {
    width: 350px;
    display: inline-block;
    text-align: center;
    background-color: rgba(191, 0, 0, 0);
}

.display-text {
    font-size: 1.5em;
    font-weight: 400;
    width: 400px;
    font-style: normal;
    color: white;
    text-align: center;
    background-color: rgba(191, 0, 0, 0);
}

.display-value {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-size: 5em;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

@media only screen and (max-width: 800px) {
    .display {
        display: block;
        margin: 0 auto;
    }

    .fila {
        display: block;
    }

    .chart {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

}

.chart {
    background-color: rgb(255, 255, 255, 0.6);
    border-radius: 5px;
    height: 80%;
    width: 80%;
    display: block;
}

.countdown {
    width: 100%;
    font-family: tiny5, arial;
    font-weight: 400;
    font-style: normal;
    color: white;

    font-size: x-large;
}

.countdown-countdown {
    margin: 30% auto;
    display: block;

    font-style: normal;
    color: white;
    text-align: center;
    font-size: 80px;
    background-color: rgba(191, 0, 0, 0);
}

.countdown-message {
    font-size: large;
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    color : rgb(209, 204, 204);
}


.countdown-wrapper {
    margin-top: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.countdown-item {
    font-size: 1.5em;
    font-weight: 400;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
}

.countdown-item span {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    background: linear-gradient(to right, #8A2BE2, #4B0082);
    margin-top: 90px;
    color: white;
}